import appLogo from "../assets/images/app-logo.png";
import appLogoDark from "../assets/images/app-logo-dark.png";
import companyLogo from "../assets/images/company-logo.png";
import homeHeroBackground from "../assets/images/home-hero-background-unbranded.png";
import sceneEditorLogo from "../assets/images/editor-logo.png";
import { getLocale, getMessage } from "./i18n";
import Cookies from "js-cookie";
import { qsGet } from "./qs_truthy";

export const CUSTOM_HOST_URL_COOKIE_NAME = "dash_url";

// Read configs from global variable if available, otherwise use the process.env injected from build.
const configs = {};
let isAdmin = false;
let isArtist = false;
[
  "RETICULUM_SERVER",
  "THUMBNAIL_SERVER",
  "CORS_PROXY_SERVER",
  "NON_CORS_PROXY_DOMAINS",
  "SENTRY_DSN",
  "GA_TRACKING_ID",
  "SHORTLINK_DOMAIN",
  "BASE_ASSETS_PATH",
  "UPLOADS_HOST",
  "APP_NAME",
  "DOCS_URL",
  "CUSTOM_API_URL",
  "CUSTOM_API_URL_V2",
  "CUSTOM_API_HOST",
  "AVATAR_PAGE_URL",
  "JWT_COOKIE",
  "CONTACT_ME_EMAIL",
  "BLOG_URL",
  "PLACE_ART_API",
  "PLACE_ART_MARKETPLACE",
  "PLACE_ART_API_TENANT_ID",
  "AR_API_SHORTENER",
  "AR_EDITOR",
  "AR_MARKETPLACE_TENANT",
  "AR_API_MARKETPLACE",
  "AUTH0_DOMAIN",
  "AUTH0_CLIENT_ID",
  "AUTH0_AUDIENCE",
  "AUTH0_COOKIE_DOMAIN",
  "LEAVE_ROOM_URL",
  "CUSTOM_HOME_PAGE_URL",
  "NODE_ENV",
  "PUBLIC_ART_API",
  "LIT_COMPONENTS_URL",
  "OPENAI_API",
  "ENABLE_SUBSCRIPTIONS",
  "PRICING_PAGE_URL",
  "SKETCHFAB_API_KEY"
].forEach(x => {
  const el = document.querySelector(`meta[name='env:${x.toLowerCase()}']`);
  configs[x] = el ? el.getAttribute("content") : process.env[x];

  const BASE_ASSETS_PATH_KEY = "BASE_ASSETS_PATH";
  if (x === BASE_ASSETS_PATH_KEY && configs[BASE_ASSETS_PATH_KEY]) {
    // BASE_ASSETS_PATH might be a relative URL like "/" when it is set in
    // .env or .defaults.env when running locally. We need to convert that
    // to an absolute URL.
    if (!configs[BASE_ASSETS_PATH_KEY].startsWith("http")) {
      configs[BASE_ASSETS_PATH_KEY] = new URL(configs[BASE_ASSETS_PATH_KEY], window.location).toString();
    }

    // eslint-disable-next-line no-undef
    __webpack_public_path__ = configs[BASE_ASSETS_PATH_KEY];
  }
});

const CUSTOM_API_HOST_REPLACEMENT = Cookies.get(CUSTOM_HOST_URL_COOKIE_NAME);

export function setCustomApiHostReplacement(replacement) {
  configs.CUSTOM_API_URL = configs.CUSTOM_API_URL
    ? configs.CUSTOM_API_URL.replace(configs.CUSTOM_API_HOST, replacement)
    : CUSTOM_API_HOST_REPLACEMENT + "/nri/api";

  configs.CUSTOM_API_URL_V2 = configs.CUSTOM_API_URL_V2
    ? configs.CUSTOM_API_URL_V2.replace(configs.CUSTOM_API_HOST, replacement)
    : "";

  configs.CUSTOM_API_HOST = replacement;
  configs.LEAVE_ROOM_URL = replacement;
}

if (CUSTOM_API_HOST_REPLACEMENT) {
  setCustomApiHostReplacement(CUSTOM_API_HOST_REPLACEMENT);
}

// Custom clients do not use <meta> tags for passing data, so if thumbnail_server meta tag exists, it is not a custom client
const hasThumbnailServerMetaTag = !!document.querySelector("meta[name='env:thumbnail_server']");
configs.IS_LOCAL_OR_CUSTOM_CLIENT = !hasThumbnailServerMetaTag;

// Also include configs that reticulum injects as a script in the page head.

configs.AVAILABLE_INTEGRATIONS = window.AVAILABLE_INTEGRATIONS || {};

if (process.env.APP_CONFIG) {
  window.APP_CONFIG = process.env.APP_CONFIG;
}

if (window.APP_CONFIG) {
  configs.APP_CONFIG = window.APP_CONFIG;
  const { theme } = configs.APP_CONFIG;
  if (theme) {
    const colorVars = [];
    for (const key in theme) {
      if (!Object.prototype.hasOwnProperty.call(theme, key)) continue;
      colorVars.push(`--${key}: ${theme[key]};`);
    }
    const style = document.createElement("style");
    style.innerHTML = `:root{${colorVars.join("\n")}}`;
    document.head.insertBefore(style, document.head.firstChild);
  }

  if (!configs.APP_CONFIG.features) {
    configs.APP_CONFIG.features = {};
  }
} else {
  configs.APP_CONFIG = {
    features: {}
  };
}

const isLocalDevelopment = process.env.NODE_ENV === "development";

configs.feature = featureName => {
  const value = configs.APP_CONFIG && configs.APP_CONFIG.features && configs.APP_CONFIG.features[featureName];
  if (typeof value === "boolean" || featureName === "enable_spoke") {
    const forceEnableSpoke = featureName === "enable_spoke" && isAdmin;
    return forceEnableSpoke || value;
  } else {
    return value;
  }
};

let localDevImages = {};
if (isLocalDevelopment) {
  localDevImages = {
    logo: appLogo,
    logo_dark: appLogoDark,
    company_logo: companyLogo,
    editor_logo: sceneEditorLogo,
    home_background: homeHeroBackground
  };
}

configs.image = (imageName, cssUrl) => {
  const url =
    (configs.APP_CONFIG && configs.APP_CONFIG.images && configs.APP_CONFIG.images[imageName]) ||
    localDevImages[imageName];
  return url && cssUrl ? `url(${url})` : url;
};

configs.link = (linkName, defaultValue) => {
  return (configs.APP_CONFIG && configs.APP_CONFIG.links && configs.APP_CONFIG.links[linkName]) || defaultValue;
};

configs.setIsAdmin = _isAdmin => {
  isAdmin = _isAdmin;
};
configs.isAdmin = () => isAdmin;

configs.setIsArtist = _isArtist => {
  isArtist = _isArtist;
};
configs.isArtist = () => isArtist;

configs.integration = integration => {
  const availableIntegrations = configs.AVAILABLE_INTEGRATIONS;
  // AVAILABLE_INTEGRATIONS has no properties defined on the dev server, but does support all integrations.
  return (
    !Object.prototype.hasOwnProperty.call(availableIntegrations, integration) || availableIntegrations[integration]
  );
};

configs.translation = key => {
  const locale = getLocale();
  const translationsConfig = (configs.APP_CONFIG && configs.APP_CONFIG.translations) || {};

  return (
    (translationsConfig[locale] && translationsConfig[locale][key]) ||
    (translationsConfig.en && translationsConfig.en[key]) ||
    getMessage(key) ||
    ""
  );
};

configs.defaultSites = () => {
  return ["hubs", "app"];
};

if (!configs.APP_NAME) {
  configs.APP_NAME = "Hubs";
}

configs.destinationUrl = (hub, embed = false) => {
  return window.location.href;
};

configs.UPGRADE_URL = configs.CUSTOM_API_HOST + "/pricing";

// If the shortlink domain includes the reticulum server and the current host is different from the reticulum server, we need to replace the reticulum server in the shortlink domain with the current host.
if (
  configs.SHORTLINK_DOMAIN?.includes(configs.RETICULUM_SERVER) &&
  configs.RETICULUM_SERVER !== window.location.hostname
) {
  configs.SHORTLINK_DOMAIN = configs.SHORTLINK_DOMAIN.replace(configs.RETICULUM_SERVER, window.location.hostname);
}

export default configs;
