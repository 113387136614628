import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./FullscreenLayout.scss";

export function Fullscreen2DLayout({
  className,
  headerLeft,
  headerCenter,
  headerRight,
  contentClassName,
  children,
  headerClassName,
  headerRightClassName
}) {
  return (
    <div className={classNames(styles.fullscreenLayout, className)}>
      <div className={classNames(styles.header, headerClassName)}>
        <div className={styles.headerLeft}>{headerLeft}</div>
        <div className={styles.headerCenter}>{headerCenter}</div>
        <div className={classNames(styles.headerRight, headerRightClassName)}>{headerRight}</div>
      </div>
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
    </div>
  );
}

Fullscreen2DLayout.propTypes = {
  className: PropTypes.string,
  headerLeft: PropTypes.node,
  headerCenter: PropTypes.node,
  headerRight: PropTypes.node,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  headerClassName: PropTypes.string
};
