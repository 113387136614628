import React, { useState, useEffect } from "react";
import { Column } from "../layout/Column";
import { Fullscreen2DLayout } from "../layout/Fullscreen2DLayout";
import styles from "./NotSupportedBrowserModal.scss";
import iconBanned from "../../assets/images/icon_banned.png";
import { Button } from "../input/Button";

export function NotSupportedBrowser() {
  const buttonColor = window.APP_CONFIG?.theme?.themes[0]?.variables["primary-color"] || "#FE12A8";

  const copyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    alert("link copied to clipboard");
  };

  return (
    <Fullscreen2DLayout contentClassName={styles.content} className={styles.layout} headerClassName={styles.header}>
      <Column padding center>
        <img className={styles.iconBanned} src={iconBanned}></img>
        <h3 className={styles.subtitle}>This App browser is blocking the XR Experience</h3>
        <Button
          onClick={copyLink}
          className={styles.button}
          style={{ backgroundColor: buttonColor, border: buttonColor }}
          preset="accent2"
          xl
        >
          COPY URL
        </Button>
        <p className={styles.text}>Copy this URL by clicking in the button and place it in your device browser.</p>
      </Column>
    </Fullscreen2DLayout>
  );
}
